/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/guide/browser-support
 */

/**
 * BROWSER POLYFILLS
 */
import '@angular/localize/init';
import 'url-polyfill';
import 'zone.js';

import { environment } from '@libs/src/environments/environment';

// TODO: Remove after angular fixes zone.js https://github.com/angular/angular/issues/45020
document.addEventListener('mousemove', () => {}, {
  capture: true,
  passive: false,
});

(window as any).global = window;

declare global {
  interface Window {
    $localize: import('@angular/localize/init').LocalizeFn;
  }
}

// Workaround for https://github.com/angular/angular/issues/44549
if (!environment.production) {
  window.$localize = function (messageParts, ...expressions) {
    let message = stripBlock(messageParts[0], messageParts.raw[0]);
    for (let i = 1; i < messageParts.length; i++) {
      message +=
        expressions[i - 1] + stripBlock(messageParts[i], messageParts.raw[i]);
    }
    return message;
  };
  const BLOCK_MARKER = ':';

  // eslint-disable-next-line no-inner-declarations
  function stripBlock(messagePart: any, rawMessagePart: any) {
    return rawMessagePart.charAt(0) === BLOCK_MARKER
      ? messagePart.substring(findEndOfBlock(messagePart, rawMessagePart) + 1)
      : messagePart;
  }

  // eslint-disable-next-line no-inner-declarations
  function findEndOfBlock(cooked: any, raw: any[]) {
    for (
      let cookedIndex = 1, rawIndex = 1;
      cookedIndex < cooked.length;
      cookedIndex++, rawIndex++
    ) {
      if ('\\' === raw[rawIndex]) {
        rawIndex++;
      } else if (cooked[cookedIndex] === BLOCK_MARKER) {
        return cookedIndex;
      }
    }
    throw new Error(`Unterminated $localize metadata block in "${raw}".`);
  }
}
